* {
    margin: 0;
}

.counter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
}

.footer {
    background-color: black;
    color: whitesmoke;
    text-align: center;
    height: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
