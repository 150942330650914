.pages-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-btn {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
