.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 70px);
    width: 75% !important;
    margin: 0 auto;

    .manu-table {
        max-height: 500;
    }
}
