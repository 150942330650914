.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
}

.classic-form {
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .form-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 1rem;

        .cancel-btn {
            margin-right: 1rem;
        }
    }
}

.type-form {
    padding: 20px;
    border-radius: 6px;
    width: 700px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .form-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 1rem;

        .cancel-btn {
            margin-right: 1rem;
        }
    }
}
